<template>
  <div>
    <div class="global-container-flex">
      <sidebar />
      <div :style="{ width: screenWidth + 'px' }" class="global-container-content-full-width">
        <navbar />
        <div class="v-container-search-bar" style="display: flex; flex-direction: column;">
          <div class="global-container-router-view" :style="{ height: screenHeight + 'px' }">
            <div class="v-container-search-bar mx-3 my-5">
              <div class="titleText" style="display: flex; align-items: center; flex-wrap: wrap; justify-content: flex-end;">
                <p class="mon-bold f-s45 l-spacing0 mb-0">
                  {{ texts.textNameScreen }}
                </p>
                <v-spacer></v-spacer>
                <div style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
                  <v-btn
                    @click="openListToUpdatePrice()"
                    :disabled="tableData.length == 0"
                    depressed
                    class="gradient-button mt-3 mr-3"
                    color="transparent"
                  >
                    {{ texts.textButtonUpdatePrice }}
                  </v-btn>
                </div>
                <div style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
                  <v-btn
                    @click="openListPrice()"
                    :disabled="tableData.length == 0"
                    depressed
                    class="gradient-button mt-3"
                    color="transparent"
                  >
                    {{ texts.textButtonListPrice }}
                  </v-btn>
                </div>
              </div>
            </div>
            <v-card class="mx-3 mt-2 mb-8">
              <v-row class="pa-4" style="margin: auto; justify-content: flex-end;">
                <v-col sm="3">
                  <v-text-field
                    v-model="sSearch"
                    solo
                    :label="texts.searchbar.search"
                    append-icon="mdi-magnify"
                    class="global-inputs-search rounded-lg mon mt-3"
                    dense
                    height="41px"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-select
                    class="global-inputs-search mon mt-3"
                    v-model="sCategoryId"
                    :items="aCategory"
                    item-text="sName"
                    item-value="sCategoryId"
                    :label="texts.searchbar.category"
                    @change="getSubCatalogo()"
                    dense
                    color="black"
                    outlined
                  ></v-select>
                </v-col>
                <v-col sm="3">
                  <v-select
                    class="global-inputs-search mon mt-3"
                    v-model="sSubCategoryId"
                    :items="aSubCategory"
                    item-text="sSubcategoryName"
                    item-value="sSubcategoryId"
                    :label="texts.searchbar.subcategory"
                    dense
                    color="black"
                    outlined
                  ></v-select>
                </v-col>
                <v-col sm="1" style="align-self: center; text-align: right">
                  <filter-component layoutItem="articles" class="mt-3" layoutmarginright="3px" />
                </v-col>
              </v-row>
              <empty-data v-if="tableData.length == 0" />
              <v-data-table
                v-else
                :headers="headers"
                :disable-sort="true"
                :items-per-page="iItemsPerPage"
                :items="tableData"
                hide-default-footer
              >
                <template v-slot:item.aImages="{ item }">
                  <img
                    :src="item.aImages"
                    alt="Product Image"
                    style="width: 80px; height: 80px; margin: 5px; border-radius: 15px; margin-bottom: 0px;"
                  />
                </template>
                <template v-slot:item.dUnitPrices="{ item }">
                  <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.dUnitPrices) + " MXN" }} </span>
                </template>
                <template v-slot:item.FOB="{ item }">
                  <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.dUnitPrice + item.dFreightPerBox + item.dTotalInOutPerBox + item.sCustomPerBox) + " USD" }} </span>
                </template>
                <template v-slot:item.dBoxesPerPallet="{ item }">
                  <span style="text-wrap: nowrap;"> {{ item.dBoxesPerPallet + " cajas" }} </span>
                </template>
                <template v-slot:item.dPalletsPerTruck="{ item }">
                  <span style="text-wrap: nowrap;"> {{ item.dPalletsPerTruck + " pallets" }} </span>
                </template>
                <template v-slot:item.oMeasurementUnit="{ item }">
                  <span> {{ item.oMeasurementUnit.sName }} </span>
                </template>
                <template v-slot:item.bVisible="{ item }">
                  <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
                    Activo
                    <!-- <v-icon class="ml-1" style="font-size: 20px;">
                      mdi-play-circle-outline
                    </v-icon> -->
                  </v-chip>
                  <v-chip v-else class="ma-2" outlined small color="#d8686b">
                    Pausado
                    <!-- <v-icon style="font-size: 20px;">
                      mdi-pause
                    </v-icon> -->
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="detailItem(item)" icon v-bind="attrs" v-on="on">
                        <v-icon size="20px" color="#292929">
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Detalle</span>
                  </v-tooltip>
                </template>

                <template v-slot:footer>
                  <hr />
                  <v-pagination color="yellow darken-1" v-model="iCurrentPage" class="mt-2  mon" :length="iNumPages"> </v-pagination>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogListPrices" persistent>
      <v-stepper v-model="e1">
        <v-stepper-header style="display: flex; flex-direction: column; flex-wrap: nowrap; height: fit-content;">
          <div style="text-align: end;">
            <v-btn class="pa-0" text @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <v-stepper-step @click="e1 = 1" :complete="e1 == 1" step="1">
              Seleccionar Artículos
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="createProductList()" :complete="e1 == 2" step="2">
              Exportar Lista de Precios
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-title>
                Artículos
                <v-spacer></v-spacer>
                <div style="display: flex; align-items: flex-end; align-items: flex-end;">
                <v-file-input
                    v-model="file"
                    @change="handleFileListPrice"
                    hide-details
                    style="min-width: 300px;"
                    multiple
                    label="Cargar prelista"
                    class="mr-4"
                  ></v-file-input>
                </div>
                <div style="display: flex; gap: 20px;">
                  <v-text-field
                    v-model="search"
                    class="pt-0 mt-0"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </v-card-title>
              <v-data-table
                show-select
                v-model="selectedArtcilesToList"
                item-key="sProductId"
                :loading="loading"
                :headers="headersList"
                :items="excelTableData"
                :search="search"
                :disable-sort="true"
              >
                <template v-slot:item.dUnitPrice="{ item }">
                  <span> {{ "$ " + item.dUnitPrice + " USD" }} </span>
                </template>
                <template v-slot:item.sLogisticCost="{ item }">
                  <span> {{ "$ " + formatMoney(item.dFreightPerBox + item.dTotalInOutPerBox + item.sCustomPerBox) + " USD" }} </span>
                </template>
                <template v-slot:item.FOB="{ item }">
                  <span> {{ "$ " + formatMoney(item.dUnitPrice + item.dFreightPerBox + item.dTotalInOutPerBox + item.sCustomPerBox) + " USD" }} </span>
                </template>

                <template v-slot:item.oMeasurementUnit="{ item }">
                  <span> {{ item.oMeasurementUnit.sName }} </span>
                </template>
                <template v-slot:item.bVisible="{ item }">
                  <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
                    Activo
                    <!-- <v-icon class="ml-1" style="font-size: 20px;">
                      mdi-play-circle-outline
                    </v-icon> -->
                  </v-chip>
                  <v-chip v-else class="ma-2" outlined small color="#d8686b">
                    Pausado
                    <!-- <v-icon style="font-size: 20px;">
                      mdi-pause
                    </v-icon> -->
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div style="display: flex; justify-content: flex-end;">
              <v-btn
                @click="generatePDF()"
                :disabled="selectedArtcilesToList.length == 0"
                depressed
                class="gradient-button"
                color="transparent"
              >
                {{ "Descargar PDF" }}
              </v-btn>
            </div>
            <div
              class="text-center"
              style="display: flex; flex-direction: column; justifyContent: center; alignItems: center; height: 300px;"
              v-if="selectedArtcilesToList.length == 0"
            >
              <img class="empty-image" src="@/assets/gif/gifEmpty.gif" alt="" />
              <p class="empty-text">
                {{ texts.emptyText }}
              </p>
            </div>
            <div v-else>
              <v-row>
                <v-col v-for="(item, index) in productList" :key="index" cols="12">
                  <div class="mon-bold mb-3">{{ item.sCategoryName }}</div>
                  <v-data-table
                    :headers="headersProductList"
                    :items="item.aProducts"
                    :items-per-page="50"
                    hide-default-footer
                    class="elevation-1"
                    :disable-sort="true"
                  >
                    <template v-slot:item.dUnitPriceUSD="{ item }">
                      <span> {{ "$ " + formatMoney(item.dUnitPriceUSD) + " USD" }} </span>
                    </template>
                    <template v-slot:item.sLogistic="{ item }">
                      <span> {{ "$ " + formatMoney(item.sLogistic) + " USD" }} </span>
                    </template>
                    <template v-slot:item.sTotalPerBox="{ item }">
                      <span> {{ "$ " + formatMoney(item.sTotalPerBox) + " USD" }} </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    <v-dialog v-model="dialogUpdatePrices" persistent>
      <v-stepper v-model="e1">
        <v-stepper-header style="display: flex; flex-direction: column; flex-wrap: nowrap; height: fit-content;">
          <div style="text-align: end;">
            <v-btn class="pa-0" text @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <v-stepper-step @click="e1 = 1" :complete="e1 == 1" step="1">
              Exportar Artículos
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="e1 = 2" :complete="e1 == 2" step="2">
              Importar Artículos
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-title>
                Artículos
                <v-spacer></v-spacer>
                <div style="display: flex; gap: 20px;">
                  <v-text-field
                    v-model="search"
                    class="pt-0 mt-0"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-btn @click="exportToXLSX()" color="green" :disabled="selected.length == 0" class="white--text" elevation="0" small>
                    Descargar <v-icon class="ml-2">mdi-microsoft-excel</v-icon></v-btn
                  >
                </div>
              </v-card-title>
              <v-data-table
                show-select
                v-model="selected"
                item-key="sProductId"
                :headers="headersExcel"
                :items="excelTableData"
                :loading="loading"
                :search="search"
                :disable-sort="true"
              >
                <template v-slot:item.dUnitPrices="{ item }">
                  <span> {{ "$" + item.dUnitPrices + " MXN" }} </span>
                </template>
                <template v-slot:item.FOB="{ item }">
                  <span> {{ "$" + formatMoney(item.dUnitPrice + item.dFreightPerBox + item.dTotalInOutPerBox + item.sCustomPerBox) + " USD" }} </span>
                </template>

                <template v-slot:item.dBoxesPerPallet="{ item }">
                  <span style="text-wrap: nowrap;"> {{ item.dBoxesPerPallet + " cajas" }} </span>
                </template>
                <template v-slot:item.dPalletsPerTruck="{ item }">
                  <span style="text-wrap: nowrap;"> {{ item.dPalletsPerTruck + " pallets" }} </span>
                </template>
                <template v-slot:item.oMeasurementUnit="{ item }">
                  <span> {{ item.oMeasurementUnit.sName }} </span>
                </template>
                <template v-slot:item.bVisible="{ item }">
                  <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
                    Activo
                    <!-- <v-icon class="ml-1" style="font-size: 20px;">
                      mdi-play-circle-outline
                    </v-icon> -->
                  </v-chip>
                  <v-chip v-else class="ma-2" outlined small color="#d8686b">
                    Pausado
                    <!-- <v-icon style="font-size: 20px;">
                      mdi-pause
                    </v-icon> -->
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div style="display: flex; align-items: flex-end; justify-content: space-between;">
              <div style="display: flex; align-items: flex-end;">
                <v-file-input
                  v-model="file"
                  @change="handleFileUpload"
                  hide-details
                  style="min-width: 300px;"
                  multiple
                  label="Subir archivo"
                ></v-file-input>
              </div>
              <v-btn @click="editArticle()" :disabled="results.length == 0" depressed class="gradient-button" color="transparent">
                {{ "Actualizar" }}
              </v-btn>
            </div>
            <div
              class="text-center"
              style="display: flex; flex-direction: column; justifyContent: center; alignItems: center; height: 300px;"
              v-if="results.length == 0"
            >
              <img class="empty-image" src="@/assets/gif/gifEmpty.gif" alt="" />
              <p class="empty-text">
                {{ texts.emptyText }}
              </p>
            </div>
            <div v-else>
              <v-row>
                <v-col v-for="(item, index) in results" :key="index" cols="12" sm="4" md="4" lg="3" xl="2">
                  <v-card class="mx-auto my-3 rounded-lg global-content-card-article">
                    <active-article-component
                      v-if="!item.bVisible"
                      :bVisible="item.bVisible"
                      :inCard="true"
                      class="global-content-active-article"
                    />
                    <div class="content-image pa-4">
                      <img class="rounded-lg" style="width: 100%; height: 100%;" :src="item.img" />
                    </div>
                    <v-card-text
                      style=" margin-bottom:-25px; text-transform: capitalize;"
                      class="f-s14 mon-bold color000000 text-article-name"
                      >{{ item.sName }}</v-card-text
                    >
                    <v-card-text>
                      <div class="f-s10 d-flex mon-SemiBold " style="color: #9AA6B1 !important;">
                        <v-icon class="mr-1" color="#9AA6B1" size="14px">
                          mdi-account
                        </v-icon>
                        {{ item.sVendorEnterpriseName }}
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-calendar
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.tProductAvailableTime }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-warehouse
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.dInventoryQuantity + " units" }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-dropbox
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.dBoxesPerPallet + " boxes per pallet" }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-dropbox
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.oMeasurementUnit  }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-dropbox
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.dPalletsPerTruck + " pallets in truck" }}
                        </span>
                      </div>
                      <div class="mon-bold mt-2 text-height text-article-price" style="font-size: 20px;color: #151D27 !important;">
                        <p class="text-article-price">${{ formatMoney(item.dUnitPrice) + " MXN" }}</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    <v-overlay style="z-index: 99999999;" :value="overlay">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
  </div>
</template>
<script>
import * as XLSX from "xlsx";

import { saveAs } from "file-saver";

import moment from "moment-timezone";

export default {
  name: "Articles",
  data() {
    return {
      search: "",
      iCurrentPage: 1,
      overlay: false,
      iItemsPerPage: 20,
      iNumPages: 0,
      iTotalItems: 0,
      sSearch: "",
      sCategoryId: "",
      aCategory: [],
      sSubCategoryId: "",
      aSubCategory: [],
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      texts: "",
      e1: 1,
      dialogUpdatePrices: false,
      dialogListPrices: false,
      selected: [],
      selectedArtcilesToList: [],
      data: [],
      results: [],
      loading: false,
      headers: [
        { text: "Producto", value: "aImages", align: "center" },
        { text: "Nombre", value: "sName" },
        { text: "Proveedor", value: "sVendorEnterpriseName" },
        { text: "Precio MXN", value: "dUnitPrices" },
        { text: "FOB", value: "FOB" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet" },
        { text: "Pallets en flete", value: "dPalletsPerTruck" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Inventario", value: "dInventoryQuantity" },
        { text: "Fecha disponible", value: "tProductAvailableTime" },
        { text: "Estado", value: "bVisible", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      headersExcel: [
        { text: "Proveedor", value: "sVendorEnterpriseName" },
        { text: "Nombre", value: "sName" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Precio MXN", value: "dUnitPrices" },
        { text: "FOB", value: "FOB" },
        { text: "Pallets en flete", value: "dPalletsPerTruck" },
        { text: "Inventario", value: "dInventoryQuantity" },
        { text: "Fecha disponible", value: "tProductAvailableTime" },
        { text: "Estado", value: "bVisible", align: "center" },
      ],
      headersList: [
        { text: "Proveedor", value: "sVendorEnterpriseName" },
        { text: "Nombre", value: "sName" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Price in Mexico", value: "dUnitPrices" },
        { text: "Grower Cost", value: "dUnitPrice" },
        { text: "Logistics", value: "sLogisticCost" },
        { text: "FOB", value: "FOB" },
        { text: "Estado", value: "bVisible", align: "center" },
      ],
      headersProductList: [
        { text: "Grower/Packer", value: "sLocation", align: "center", class: "background06b050" },
        { text: "Product", value: "sProductName", align: "center", class: "background06b050" },
        { text: "Boxes / Pallet", value: "dBoxesPerPallet", align: "center", class: "background06b050" },
        { text: "Price", value: "dUnitPriceUSD", align: "center", class: "background06b050" },
        { text: "Logistics*", value: "sLogistic", align: "center", class: "background06b050" },
        { text: "FOB", value: "sTotalPerBox", align: "center", class: "background06b050" },
      ],
      tableData: [],
      excelTableData: [],
      reportTypeGeneral: "",
      file: null,
      sheetsGeneral: [],
      jsonData: null,
      dataWithHeaders: null,
      today: "",
      productList: null,
    };
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
    this.getCatalogo();
    this.fillTable();
    this.today = moment().format("DD-MM-YYYY");
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    createProductList() {
      this.e1 = 2;
      this.productsList = this.selectedArtcilesToList.map((item) => {
        const { sSubcategoryName, sVendorEnterpriseName, oMeasurementUnit, sName, dBoxesPerPallet, dUnitPrice, imgCat, dTotalInOutPerBox, dFreightPerBox, sCustomPerBox } = item;
        return { sSubcategoryName, sVendorEnterpriseName, sName, oMeasurementUnit, dBoxesPerPallet, dUnitPrice, imgCat, dTotalInOutPerBox, dFreightPerBox, sCustomPerBox };
      });

      const groupedData = this.productsList.reduce((result, currentItem) => {
        const category = currentItem.sSubcategoryName;

        if (!result[category]) {
          result[category] = {
            sCategoryName: category,
            sCategoryImg: currentItem.imgCat,
            aProducts: [],
          };
        }

        result[category].aProducts.push({
          sLocation: currentItem.sVendorEnterpriseName,
          sProductName: currentItem.sName,
          dBoxesPerPallet: currentItem.dBoxesPerPallet + " " + currentItem.oMeasurementUnit.sName,
          dUnitPriceUSD: (currentItem.dUnitPrice).toFixed(2),
          sLogistic: (currentItem.dTotalInOutPerBox + currentItem.dFreightPerBox + currentItem.sCustomPerBox).toFixed(2),
          sTotalPerBox: (currentItem.dUnitPrice + currentItem.dTotalInOutPerBox + currentItem.dFreightPerBox + currentItem.sCustomPerBox).toFixed(2),
        });

        return result;
      }, {});

      this.productList = Object.values(groupedData);
      
      this.productList.forEach(obj => {
        obj.aProducts.sort((a, b) => a.sTotalPerBox - b.sTotalPerBox);
      });
      this.productList.sort((a, b) => a.sCategoryName.localeCompare(b.sCategoryName));
    },
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = this.heightTable[0].clientHeight > 760 || window.innerHeight < 800;
      }
    },
    detailItem(item) {
      this.$router.push("/admin/article-detail/" + item.sProductId);
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
    fillTable() {
      this.tableData = [];
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          sCategoryId: this.sCategoryId,
          sSubcategoryId: this.sSubcategoryId,
          sBorderWarehouseId: "2a9a5c8f-5806-4d00-bc92-45a14c7974ad",
        },
      })
        .then((response) => {
          this.tableData = response.data.results;
          let i = 0;
          this.tableData = response.data.results.map((e) => {
            i++;
            return {
              ...e,
              aImages: e.aImages.length > 0 ? e.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`),
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openListPrice() {
      this.dialogListPrices = true;
      this.fillTableListPrice();
    },
    openListToUpdatePrice() {
      this.dialogUpdatePrices = true;
      this.fillUpdatePrice();
    },
    fillTableListPrice() {
      this.excelTableData = [];
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sBorderWarehouseId: "2a9a5c8f-5806-4d00-bc92-45a14c7974ad",
          bDropDown: true,
        },
      })
        .then((response) => {
          this.excelTableData = response.data.results.map((e) => {
            return {
              ...e,
              imgCat: e.oImages.xs,
              img: e.aImages.length > 0 ? e.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`),
            };
          });
          this.loading = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    fillUpdatePrice() {
      this.excelTableData = [];
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sBorderWarehouseId: "2a9a5c8f-5806-4d00-bc92-45a14c7974ad",
        },
      })
        .then((response) => {
          this.excelTableData = response.data.results.map((e) => {
            return {
              ...e,
              imgCat: e.catImages.xs,
              img: e.aImages.length > 0 ? e.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`),
            };
          });
          this.loading = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    exportToXLSX() {
      if (this.selected.length > 0) {
        let data = this.selected.map((item) => {
          return {
            uId: item.sProductId ? item.sProductId : "",
            Proveedor: item.sVendorEnterpriseName ? item.sVendorEnterpriseName : "",
            Nombre: item.sName ? item.sName : "",
            Precio: item.dUnitPrices ? item.dUnitPrices : "",
            "Cajas por pallet": item.dBoxesPerPallet ? item.dBoxesPerPallet : "",
            "Pallets en flete": item.dPalletsPerTruck ? item.dPalletsPerTruck : "",
            "Tipo de caja": item.oMeasurementUnit.sName ? item.oMeasurementUnit.sName : "",
            Inventario: item.dInventoryQuantity >= 0 ? item.dInventoryQuantity : "",
            "Fecha disponible": item.tProductAvailableTime ? item.tProductAvailableTime : "",
            "Id de almacen": item.sVendorWarehouseId ? item.sVendorWarehouseId : "",
            Estado: item.bVisible ? "Activo" : "Inactivo",
          };
        });

        let nameXLSX = "Lista_Precios_" + moment().format("YYYY-MM-DD") + ".xlsx";
        // Crea una hoja de cálculo y agrega los datos
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Crea un libro de trabajo y agrega la hoja de cálculo
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
        // Convierte el libro de trabajo a un archivo binario
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        // Crea un Blob a partir del archivo binario
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        // Descarga el archivo utilizando FileSaver.js
        saveAs(blob, nameXLSX);
      }
    },
    setDates(date) {
      const fechaConvertida = moment(date, "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");
      return fechaConvertida;
    },
    async handleFileUpload(event) {
      const file = event[0];
      const reader = new FileReader();
      this.jsonData = [];
      if (file) {
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Guarda el JSON en los datos del componente
          this.jsonData = json;
          const headers = [
            "sProductId",
            "sVendorEnterpriseName",
            "sName",
            "dUnitPrice",
            "dBoxesPerPallet",
            "dPalletsPerTruck",
            "oMeasurementUnit",
            "dInventoryQuantity",
            "tProductAvailableTime",
            "sVendorWarehouseId",
            "bVisible",
          ];
          this.jsonData = this.jsonData.slice(1); // Resto de elementos del JSON contienen los datos

          this.jsonData = this.jsonData.map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          });

          const idsConDiferencias = [];
          this.excelTableData.forEach((objeto) => {
            const sProductId = objeto.sProductId;
            const objetoSegundoArray = this.jsonData.find((item) => item.sProductId === sProductId);
            if (objetoSegundoArray) {
              const tieneDiferencias =
                objeto.dUnitPrices !== objetoSegundoArray.dUnitPrice ||
                objeto.dInventoryQuantity !== objetoSegundoArray.dInventoryQuantity ||
                objeto.tProductAvailableTime !== objetoSegundoArray.tProductAvailableTime ||
                objeto.dBoxesPerPallet !== objetoSegundoArray.dBoxesPerPallet ||
                objeto.dPalletsPerTruck !== objetoSegundoArray.dPalletsPerTruck
              if (tieneDiferencias) {
                objetoSegundoArray.img =
                  objeto.aImages.length > 0 ? objeto.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`);
                idsConDiferencias.push(objetoSegundoArray);
              }
            }
          });
          this.results = idsConDiferencias;
          console.log(this.results)
        };
        reader.readAsArrayBuffer(file);
      } else {
        this.results = [];
      }
    },
    async handleFileListPrice(event) {
      const file = event[0];
      const reader = new FileReader();
      this.jsonData = [];
      if (file) {
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Guarda el JSON en los datos del componente
          this.jsonData = json;
          const headers = [
            "sProductId",
          ];
          this.jsonData = this.jsonData.slice(1); // Resto de elementos del JSON contienen los datos

          this.jsonData = this.jsonData.map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          });

          this.selectedArtcilesToList = this.excelTableData.filter(itemTabla => {
            // Suponiendo que sProductId es una propiedad de itemTabla
            return this.jsonData.some(itemJson => itemJson.sProductId === itemTabla.sProductId);
          });
        };
        reader.readAsArrayBuffer(file);
      } else {
        this.results = [];
      }
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    getCatalogo() {
      this.aSubCategory = [];
      this.sSubCategoryId = "";

      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          tStart: this.startDate,
          tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.aCategory = response.data.results;
          if (this.selectLanguage == "sp") {
            this.aCategory.unshift({ sName: "Todos", sCategoryId: "" });
            this.aSubCategory.unshift({ sSubcategoryName: "Todos", sSubcategoryId: "" });
          } else {
            this.aCategory.unshift({ sName: "All", sCategoryId: "" });
            this.aSubCategory.unshift({ sSubcategoryName: "All", sSubcategoryId: "" });
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getSubCatalogo() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sCategoryId: this.sCategoryId,
        },
      })
        .then((response) => {
          this.aSubCategory = response.data.results;
          if (this.selectLanguage == "sp") {
            this.aSubCategory.unshift({ sSubcategoryName: "Todos", sSubcategoryId: "" });
          } else {
            this.aSubCategory.unshift({ sSubcategoryName: "All", sSubcategoryId: "" });
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    editArticle() {
      this.overlay = true;
      const params = this.results.map((obj) => {
        const { sProductId, dUnitPrice, dInventoryQuantity, tProductAvailableTime, sVendorWarehouseId, dBoxesPerPallet, dPalletsPerTruck } = obj;
        const fechaConvertida = moment(tProductAvailableTime, "DD-MM-YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");

        return { sProductId, dUnitPrice, dInventoryQuantity, tProductAvailableTime: fechaConvertida, sVendorWarehouseId, dBoxesPerPallet, dPalletsPerTruck };
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/products/update/prices`, params, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.cancel();
          this.fillTable();
          this.fillUpdatePrice();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    cancel() {
      this.dialogUpdatePrices = false;
      this.dialogListPrices = false;
      this.results = [];
      this.search = "";
      this.selectedArtcilesToList = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
    },
    generatePDF() {
      this.overlay = true;
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.$store.state.sTokenPdf}`,
        },
      };
      const params = {
        template: {
          id: 994590,
          data: [
            {
              list: this.productList,
              date: this.today,
            },
          ],
        },
        format: "pdf",
        output: "base64",
        name: "Lista de Precios",
      };

      DB.post("https://us1.pdfgeneratorapi.com/api/v4/documents/generate", params, config)
        .then((response) => {
          let namePDF = "Lista Precios " + moment().format("MM-DD-YYYY HH:mm") + ".pdf";

          // Convertir el Base64 en un archivo Blob
          const byteCharacters = atob(response.data.response);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const pdfBlob = new Blob([byteArray], { type: "application/pdf" });

          // Descarga el archivo PDF utilizando FileSaver.js
          saveAs(pdfBlob, namePDF);
          this.overlay = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.overlay = false;
        });
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
      }
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function() {
      this.fillTable();
    },
    sSubcategoryId: function() {
      this.fillTable();
    },
    iCurrentPage: function() {
      this.fillTable();
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
      this.fillTable();
      this.fillUpdatePrice();
      this.getCatalogo();
    },
  },
};
</script>
<style scoped>
.v-input-search:focus-within {
  background-color: transparent;
  color: #292929;
  outline: 0;
  border-bottom: 1px solid #292929;
}

.v-input-search {
  outline: 0;
  color: #292929;
  font-family: "mon";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.29px;
}

/* .v-input-search::placeholder {
    color: #292929;
    font-family: "mon";
    font-size: 12px;
    font-style: italic;
    letter-spacing: -0.29px;
  } */

.text-calendar {
  margin-bottom: 0;
  font-size: 13px;
  color: #292929;
  font-weight: 500;
  letter-spacing: -0.29px;
}

.v-container-flex {
  display: flex;
  align-items: center;
}

.v-container-flex-searchbar {
  display: flex;
  align-items: center;
  padding: 0px 15px;
}

.v-input-search {
  margin-right: 10px;
}

.v-input-date .v-icon {
  color: white !important;
}

.v-button-search {
  height: 28px !important;
  width: 103px;
  border-radius: 12px;
  background-color: #292929 !important;
  color: #ffffff;
  font-family: "mon";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.11px;
  line-height: 14px;
  text-align: center;
}

.v-container-search-bar {
  background-color: #f2f2f4;
  /* border-bottom: 1px solid #d4d2c0; */
  /* padding: 15px; */
  /* display: flex; */
  /* align-items: center; */
}

.v-text-searchbar {
  color: #292929;
  font-size: 35px;
  font-weight: 900;
  letter-spacing: 0;
  margin-bottom: 0;
  font-family: "mon-bold", sans-serif;
  margin-top: -8px;
}

.titleText {
  width: 100%;
}
.searchInput {
  width: 100%;
}
.categories-filter {
  width: 100%;
  display: flex;
}
.sectionCategories {
  width: 100%;
  display: flex;
}
.categoryInput {
  width: 100%;
  margin-left: 20px;
}
.subcategoryInput {
  width: 100%;
  margin-left: 20px;
}
.filterBtn {
  width: 10%;
  margin-left: 20px;
}

/* XS */
@media (max-width: 599px) {
  .v-container-search-bar {
    /* background-color: RED; */
  }
  .searchInput {
    margin-bottom: 10px;
  }
  .categories-filter {
    display: block;
  }
  .sectionCategories {
    display: block;
  }
  .categoryInput {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .subcategoryInput {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .filterBtn {
    width: 100% !important;
    display: block !important;
    margin-left: 0px;
    justify-content: end !important;
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .v-container-search-bar {
    /* background-color: YELLOW; */
  }
  .searchInput {
    margin-bottom: 10px;
  }
  .sectionCategories {
    margin-bottom: 10px;
  }
  .categoryInput {
    width: 100%;
    margin-left: 0px;
  }
  .filterBtn {
    width: 10% !important;
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: BLUE; */
  }
  .filterBtn {
    width: 15%;
    margin-left: 20px;
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: PINK; */
  }
  .filterBtn {
    width: 15%;
    margin-left: 20px;
  }
}
/* XL */
@media (min-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: GREEN; */
  }
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
.content-image {
  width: 100%;
  height: 250px;
}

.content-image img {
  width: 100%;
  height: auto;
}
.empty-image {
  width: 200px;
}
</style>
